






































import { Component, Watch } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { ContainerWidth, FormFieldset } from '../../../atoms'
import { Iterator, MapSelector } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'
import {
  MosaicModule,
  MosaicModuleBaseContent,
  MosaicModuleVersion
} from '../Mosaic.contracts'

import { mosaicContentFactory } from '../Mosaic.factory'
import { MosaicSlideForm } from './partials/MosaicSlideForm.vue'

@Component({
  name: 'MosaicModuleForm',
  components: {
    MosaicSlideForm,
    FormFieldset,
    Iterator,
    MapSelector
  }
})
export class MosaicModuleForm extends AbstractModuleForm<MosaicModule> {
  public initialContent: MosaicModuleBaseContent = mosaicContentFactory()

  public readonly ContainerWidth = ContainerWidth

  public readonly DashmixIconName = DashmixIconName

  public readonly MosaicModuleVersion = MosaicModuleVersion

  @Watch('_version')
  private onVersionChange (newVersion: MosaicModuleVersion): void {
    switch (newVersion) {
      case MosaicModuleVersion.Basic:
        // when chosen version is `basic` removes slide list and add addons and backgroundImage
        this._content = {
          containerWidth: this._content.containerWidth,
          addons: []
        }
        break
      default:
        throw new Error(`[MosaicModuleForm.onVersionChange()]: Not supported version of Mosaic module. Chosen version: ${newVersion}.`)
    }
  }
}

export default MosaicModuleForm
