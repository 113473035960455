


















































import { AsyncComponent } from 'vue'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { ExposesRelatedRecord, ResolvesRelatedAsync } from '../../../../../services'
import { log } from '../../../../../support'
import { PickerCallback, Related, RelatedType } from '../../../../../contracts'

import { FormFieldset } from '../../../../atoms'
import { ImageForm, GalleryForm, MapSelector, Iterator } from '../../../../molecules'

import { EmbedAddon, ImageAddon } from '../../addons'
import {
  MosaicModuleAddon, MosaicModuleAddonSize,
  MosaicModuleAddonType,
  MosaicModuleSlideContent, MosaicModuleVersion
} from '../../Mosaic.contracts'
import { MosaicModuleAddonTypeFormComponentRegistry } from '../../Mosaic.config'
import { DashmixIconName } from '@movecloser/ui-core'

@Component({
  name: 'MosaicSlideForm',
  computed: {
    DashmixIconName () {
      return DashmixIconName
    }
  },
  components: { ImageForm, GalleryForm, MapSelector, FormFieldset, Iterator }
})
export class MosaicSlideForm extends Vue {
  @PropSync('slide', { type: Object, required: true })
  public _slide!: MosaicModuleSlideContent

  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: ResolvesRelatedAsync & ExposesRelatedRecord

  @Prop({ type: String, required: true })
  public readonly version!: MosaicModuleVersion

  public readonly MosaicModuleAddonType = MosaicModuleAddonType

  public readonly MosaicModuleAddonSize = MosaicModuleAddonSize

  public changeAddonType (addonType: MosaicModuleAddonType, index: number): void {
    const addonsCopy = [...this._slide.addons]

    const defaultSize = MosaicModuleAddonSize.Small

    switch (addonType) {
      case MosaicModuleAddonType.Embed:
        (addonsCopy[index] as EmbedAddon) = {
          type: MosaicModuleAddonType.Embed,
          size: addonsCopy[index].size || defaultSize,
          videoId: '',
          description: '',
          title: '',
          thumbnail: {} as Related<RelatedType.File>,
          link: {
            label: '',
            target: ''
          }
        }
        break

      case MosaicModuleAddonType.Image:
        (addonsCopy[index] as ImageAddon) = {
          type: MosaicModuleAddonType.Image,
          size: addonsCopy[index].size || defaultSize,
          description: '',
          title: '',
          thumbnail: {} as Related<RelatedType.File>,
          link: {
            label: '',
            target: ''
          }
        }
        break
    }

    this._slide.addons = addonsCopy
  }

  public changeAddonSize (addonSize: MosaicModuleAddonSize, index: number): void {
    const addonsCopy = [...this._slide.addons]
    addonsCopy[index].size = addonSize
    this._slide.addons = addonsCopy
  }

  /**
   * Returns applicable form component for the passed-in `MosaicModuleAddonType`.
   *
   * @param addonType
   */
  public getAddonComponent (addonType: MosaicModuleAddonType): AsyncComponent | undefined {
    const component = MosaicModuleAddonTypeFormComponentRegistry[addonType]

    if (typeof component === 'undefined') {
      log(`MosaicModuleForm.getAddonComponent(): There's no component binding for the passed-in [${addonType}] MosaicModuleAddonType!`, 'warn')
      return
    }

    return component
  }

  /**
   * Prepares empty addon
   */
  public prepareAddon (): MosaicModuleAddon {
    return {
      type: MosaicModuleAddonType.Image,
      size: MosaicModuleAddonSize.Small
    }
  }
}

export default MosaicSlideForm
